/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useRef, useState, useEffect } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import CountUp from "react-countup"

import Layout from "../components/layout"
import TestimonialsSlider from "../components/testimonials-slider"
import { IMG, VIDEO } from "../const"
import * as dashboardStyles from "../assets/styles/dashboard.module.scss"
import {
  OPTIONS,
  PROJECTS,
  DASHBOARD_PROPERTIES,
  ANIMATED_COUNTERS,
} from "../data/dashboardPage"

const ProjectWrapper = ({ project, children }) => (
  <>
    {project.externalLink ? (
      <a
        href={project.externalLink}
        className={dashboardStyles.caseItem}
        itemScope
        itemType="http://schema.org/ImageObject"
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : project.internalLink ? (
      <Link
        to={project.internalLink}
        className={dashboardStyles.caseItem}
        itemScope
        itemType="http://schema.org/ImageObject"
      >
        {children}
      </Link>
    ) : null}
  </>
)

const DashboardPage = () => {
  const [startCount, setStartCount] = useState(false)
  const countAnimationDuration = 3
  const counterBlock = useRef(null)

  useEffect(() => {
    const handleScroll = e => {
      const counterBlockOffset = counterBlock.current.getBoundingClientRect()
      const counterBlockPosition = counterBlockOffset.top - 200
      if (!startCount && counterBlockPosition < 0) {
        setStartCount(true)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })
    return function cleanup() {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [startCount, countAnimationDuration])

  return (
    <Layout pageTitle="Beautiful dashboards adapted specifically for your needs">
      {({ handleDashboardExpertise, isOpenContactModal }) => {
        return (
          <main className={dashboardStyles.dashboardBlock}>
            <article>
              <div className={dashboardStyles.dashboardBlock__headline}>
                <h1>Your own Dashboard expert</h1>
                <p>Beautiful Dashboards adapted specially for your needs</p>
                <p>
                  <Link
                    className="goldButton__w14 darkBackground"
                    to="#showCase"
                  >
                    Show case
                  </Link>
                </p>
              </div>
              <section
                className={cx(
                  dashboardStyles.videoSection,
                  "common__article__section"
                )}
                dangerouslySetInnerHTML={{
                  __html: `
								<video
									poster="${`${VIDEO}/dashboard/0480.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range0_479)}"
									>
									<source src="${`${VIDEO}/dashboard/0480.mp4`}" type="video/mp4"/>
								</video>
								<video
									poster="${`${VIDEO}/dashboard/0640.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range480_639)}"
									>
									<source src="${`${VIDEO}/dashboard/0640.mp4`}" type="video/mp4"/>
								</video>
								<video
									poster="${`${VIDEO}/dashboard/0720.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range640_719)}"
									>
									<source src="${`${VIDEO}/dashboard/0720.mp4`}" type="video/mp4"/>
								</video>
								<video
									poster="${`${VIDEO}/dashboard/1080.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range720_1079)}"
									>
									<source src="${`${VIDEO}/dashboard/1080.mp4`}" type="video/mp4"/>
								</video>
								<video
									poster="${`${VIDEO}/dashboard/1280.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range1080_1279)}"
									>
									<source src="${`${VIDEO}/dashboard/1280.mp4`}" type="video/mp4"/>
								</video>
								<video
									poster="${`${VIDEO}/dashboard/1920.jpg`}"
									autoplay
									muted
									loop
									playsinline
									disableremoteplayback
									preload="auto"
									class="${cx(dashboardStyles.dashVideo, dashboardStyles.range1280)}"
									>
									<source src="${`${VIDEO}/dashboard/1920.mp4`}" type="video/mp4"/>
								</video>
							`,
                }}
              ></section>
              <section
                className={cx(
                  dashboardStyles.canBe,
                  "common__article__section"
                )}
              >
                <h2>Your Dashboard can be</h2>
                <ul>
                  {DASHBOARD_PROPERTIES.map(item => (
                    <li key={item.id}>
                      <div>
                        <img
                          src={`${IMG.ICONS}${item.image}`}
                          alt={item.title}
                          width="64"
                          height="64"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
              <section className="bg__nobleGray">
                <div className="common__article__section upTo2colsImgTxt">
                  <div>
                    <img
                      src={`${IMG.DASHBOARD}/audit-illustration.svg`}
                      width="288"
                      height="192"
                      alt="Start with an audit"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h2>Start with an audit</h2>
                    <p>
                      The audit is an essential step to understand your data and
                      recognize potential opportunities for you. We are here to
                      help! We are going to understand your needs and prepare
                      solutions.
                    </p>
                    <p>
                      <button
                        className="goldButton__casePorter dashboard-buy"
                        onClick={handleDashboardExpertise}
                        title="Buy now"
                        aria-expanded={isOpenContactModal ? "true" : "false"}
                        aria-label={
                          isOpenContactModal
                            ? "Hide contact form"
                            : "Show contact form"
                        }
                      >
                        Buy now
                      </button>
                    </p>
                  </div>
                </div>
              </section>
              <section className="common__article__section">
                <h2>Clients’ testimonials</h2>
                <TestimonialsSlider />
              </section>
              <section
                className={cx(dashboardStyles.workProcess, "bg__nobleGray")}
              >
                <div className="common__article__section">
                  <ul>
                    {OPTIONS.map(option => (
                      <li key={option.id}>
                        <h3>
                          <img
                            src={`${IMG.ICONS}${option.image}`}
                            width="120"
                            height="120"
                            alt={option.title}
                            loading="lazy"
                          />
                          {option.title}
                        </h3>
                        <p>{option.text}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
              <section className={dashboardStyles.showCase} id="showCase">
                <div className="common__article__section">
                  <h2>Show case</h2>
                  <ul>
                    {PROJECTS.map(project => (
                      <li key={project.id}>
                        <ProjectWrapper project={project}>
                          <div className={dashboardStyles.caseImg}>
                            <picture>
                              <source
                                media="(max-width: 428px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}382.avif,	${IMG.PORTFOLIO}${project.imageUrl}764.avif 2x,	${IMG.PORTFOLIO}${project.imageUrl}984.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                media="(max-width: 428px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}382.webp,	${IMG.PORTFOLIO}${project.imageUrl}764.webp 2x,	${IMG.PORTFOLIO}${project.imageUrl}984.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                media="(max-width: 428px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}382.jpg,	${IMG.PORTFOLIO}${project.imageUrl}764.jpg 2x,		${IMG.PORTFOLIO}${project.imageUrl}984.jpg 3x`}
                              />
                              <source
                                media="(min-width: 429px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}594.avif,	${IMG.PORTFOLIO}${project.imageUrl}1188.avif 2x,	${IMG.PORTFOLIO}${project.imageUrl}1782.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                media="(min-width: 429px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}594.webp,	${IMG.PORTFOLIO}${project.imageUrl}1188.webp 2x,	${IMG.PORTFOLIO}${project.imageUrl}1782.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                media="(min-width: 429px)"
                                srcSet={`${IMG.PORTFOLIO}${project.imageUrl}594.jpg,	${IMG.PORTFOLIO}${project.imageUrl}1188.jpg 2x,	${IMG.PORTFOLIO}${project.imageUrl}1782.jpg 3x`}
                              />
                              <img
                                src={`${IMG.PORTFOLIO}${project.imageUrl}1782.jpg`}
                                alt={project.title}
                                itemProp="contentUrl url"
                                width="288"
                                height="164"
                                loading="lazy"
                              />
                              <meta itemProp="width" content="1782" />
                              <meta itemProp="height" content="1008" />
                            </picture>
                          </div>
                          <div className={dashboardStyles.caseTxt}>
                            <h3 itemProp="name">{project.title}</h3>
                            {/* {project.linkText && <p itemProp="description"><strong>{project.linkText}</strong></p>} */}
                            {project.text}
                            {project.externalLink && (
                              <p>
                                <span
                                  className={cx(
                                    dashboardStyles.caseItemButton,
                                    "childAs__seeMore"
                                  )}
                                >
                                  Visit project
                                </span>
                              </p>
                            )}
                            {project.internalLink && (
                              <p>
                                <span
                                  className={cx(
                                    dashboardStyles.caseItemButton,
                                    "childAs__seeMore"
                                  )}
                                >
                                  Case study
                                </span>
                              </p>
                            )}
                          </div>
                        </ProjectWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
              <section
                className={cx(
                  dashboardStyles.ourStory,
                  "common__article__section"
                )}
                ref={counterBlock}
              >
                <div className={dashboardStyles.ourStoryGrid}>
                  <div className={dashboardStyles.text}>
                    <h2>Our story</h2>
                    <p>
                      LaSoft was founded in the middle of&nbsp;2014 by
                      2&nbsp;software development professionals.
                    </p>
                    <p>
                      Currently, LaSoft is a&nbsp;trusted technology partner for
                      businesses, consulting companies, and startups. Web
                      &&nbsp;Mobile Development Agency works with partners
                      worldwide, from the USA, Canada, Netherlands, Israel,
                      Germany, Switzerland, and the UK to&nbsp;Saudi Arabia and
                      Nigeria. Top Silicon Valley Companies use the products we
                      build.
                    </p>
                    <p>
                      For almost 10&nbsp;years, 20&nbsp;highly productive teams
                      successfully delivered more than 85+ big web projects and
                      continue to support them!
                    </p>
                  </div>
                  <div className={dashboardStyles.counterItemContainer}>
                    {ANIMATED_COUNTERS.map(item => (
                      <div
                        className={dashboardStyles.counterItem}
                        key={item.id}
                      >
                        <div className={dashboardStyles.imgBox}>
                          <img
                            src={`${IMG.ICONS}${item.image}`}
                            width="80"
                            height="80"
                            alt={item.title}
                            loading="lazy"
                          />
                        </div>
                        {startCount && (
                          <CountUp
                            start={item.start}
                            end={item.end}
                            delay={item.delay}
                            duration={countAnimationDuration}
                            useEasing={false}
                            suffix={item.suffix ? item.suffix : ""}
                          >
                            {({ countUpRef }) => (
                              <div
                                className={dashboardStyles.count}
                                ref={countUpRef}
                              />
                            )}
                          </CountUp>
                        )}
                        <div>{item.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </article>
          </main>
        )
      }}
    </Layout>
  )
}

export default DashboardPage
