// extracted by mini-css-extract-plugin
export var at1024__pr50p = "dashboard-module--at1024__pr50p--85ad6";
export var at640__pr50p = "dashboard-module--at640__pr50p--164e8";
export var at768__w50p = "dashboard-module--at768__w50p--84470";
export var bg__nobleGray = "dashboard-module--bg__nobleGray--ef38a";
export var billetShadow = "dashboard-module--billet-shadow--f1aaf";
export var btn = "dashboard-module--btn--6f91b";
export var c = "dashboard-module--c--4d8fe";
export var canBe = "dashboard-module--canBe--724bd";
export var caseImg = "dashboard-module--caseImg--63602";
export var caseItem = "dashboard-module--caseItem--37074";
export var caseItemButton = "dashboard-module--caseItemButton--b8f2b";
export var caseTxt = "dashboard-module--caseTxt--c9378";
export var cc18sf = "dashboard-module--cc-18sf--a22b9";
export var cc199t = "dashboard-module--cc-199t--9ed3e";
export var cc1ada = "dashboard-module--cc-1ada--db68e";
export var cc1aev = "dashboard-module--cc-1aev--b6ba9";
export var cc1lut = "dashboard-module--cc-1lut--42132";
export var cc1xry = "dashboard-module--cc-1xry--ef79e";
export var cc2hzo = "dashboard-module--cc-2hzo--c0cd5";
export var cc2n8m = "dashboard-module--cc-2n8m--736aa";
export var cc4xbu = "dashboard-module--cc-4xbu--133b0";
export var ccCaj5 = "dashboard-module--cc-caj5--a7952";
export var ccEw5j = "dashboard-module--cc-ew5j--18dc2";
export var ccG73w = "dashboard-module--cc-g73w--55610";
export var ccKgeu = "dashboard-module--cc-kgeu--f00a0";
export var ccKv6t = "dashboard-module--cc-kv6t--3878a";
export var ccPjpe = "dashboard-module--cc-pjpe--2aa11";
export var ccUnoo = "dashboard-module--cc-unoo--408db";
export var childAs__seeMore = "dashboard-module--childAs__seeMore--4b0f2";
export var colorScheme__background__grayPale = "dashboard-module--colorScheme__background__gray-pale--82b3a";
export var colorScheme__buttonGold = "dashboard-module--colorScheme__buttonGold--3c62f";
export var colorScheme__buttonGold__darkBackground = "dashboard-module--colorScheme__buttonGold__darkBackground--d21ff";
export var colorScheme__childAs__linkGold__darkBackground = "dashboard-module--colorScheme__childAs__linkGold__darkBackground--5cec1";
export var colorScheme__linkGold = "dashboard-module--colorScheme__linkGold--240a0";
export var colorScheme__whiteButton = "dashboard-module--colorScheme__whiteButton--5484a";
export var coloredBackground__dark = "dashboard-module--coloredBackground__dark--4ad2f";
export var coloredBackground__light = "dashboard-module--coloredBackground__light--8aa7f";
export var common__article__section = "dashboard-module--common__article__section--30a18";
export var common__article__section__doubleIndentBottom = "dashboard-module--common__article__section__doubleIndentBottom--fbc1a";
export var common__article__section__zeroIndentBottom = "dashboard-module--common__article__section__zeroIndentBottom--589d2";
export var common__article__section__zeroIndentTop = "dashboard-module--common__article__section__zeroIndentTop--c3821";
export var common__chips = "dashboard-module--common__chips--03e8b";
export var common__headline = "dashboard-module--common__headline--de11b";
export var common__headline__beveledBottom = "dashboard-module--common__headline__beveledBottom--336fe";
export var common__headline__bgLoop = "dashboard-module--common__headline__bgLoop--dae79";
export var common__leadQuote = "dashboard-module--common__leadQuote--1baa3";
export var common__projectsTeaser = "dashboard-module--common__projects-teaser--54507";
export var common__scrollableTarget = "dashboard-module--common__scrollableTarget--f74c4";
export var common__teaser = "dashboard-module--common__teaser--5863e";
export var common__teaser__button = "dashboard-module--common__teaser__button--916f9";
export var common__textOutline = "dashboard-module--common__textOutline--84bfd";
export var count = "dashboard-module--count--87d90";
export var countSymbol = "dashboard-module--count-symbol--072de";
export var counterItem = "dashboard-module--counter-item--6c6e2";
export var counterItemContainer = "dashboard-module--counter-item-container--4234f";
export var crispChatbox = "dashboard-module--crisp-chatbox--97892";
export var crispClient = "dashboard-module--crisp-client--6717c";
export var darkBackground = "dashboard-module--darkBackground--0b4fc";
export var darkTxt = "dashboard-module--darkTxt--661fa";
export var dashVideo = "dashboard-module--dash-video--0622a";
export var dashboardBlock = "dashboard-module--dashboardBlock--09be1";
export var dashboardBlock__headline = "dashboard-module--dashboardBlock__headline--bdfc0";
export var fatButton = "dashboard-module--fatButton--602d3";
export var fs1o5 = "dashboard-module--fs1o5--a8013";
export var fullWidth = "dashboard-module--fullWidth--14274";
export var goldBackground = "dashboard-module--goldBackground--62a92";
export var goldButton = "dashboard-module--goldButton--00974";
export var goldButton__casePorter = "dashboard-module--goldButton__casePorter--70f7c";
export var goldButton__centered = "dashboard-module--goldButton__centered--6239d";
export var goldButton__w14 = "dashboard-module--goldButton__w14--754c1";
export var goldFatLink = "dashboard-module--goldFatLink--b5c18";
export var icomoon = "dashboard-module--icomoon--89eb7";
export var imgBox = "dashboard-module--img-box--a5716";
export var l = "dashboard-module--l--1e159";
export var lineBreak = "dashboard-module--lineBreak--ae5ea";
export var lowercase = "dashboard-module--lowercase--74bff";
export var maxw20 = "dashboard-module--maxw20--b4b19";
export var mb2 = "dashboard-module--mb2--4bbe2";
export var mt2 = "dashboard-module--mt2--1393c";
export var nobr = "dashboard-module--nobr--54d41";
export var ourStory = "dashboard-module--ourStory--363f2";
export var ourStoryGrid = "dashboard-module--ourStory-grid--89bfc";
export var primaryButton = "dashboard-module--primaryButton--90254";
export var pt2 = "dashboard-module--pt2--d1fee";
export var r = "dashboard-module--r--9171d";
export var range0_479 = "dashboard-module--range0_479--b7a74";
export var range1080_1279 = "dashboard-module--range1080_1279--429ad";
export var range1280 = "dashboard-module--range1280--073c2";
export var range480_639 = "dashboard-module--range480_639--eb106";
export var range640_719 = "dashboard-module--range640_719--4a81d";
export var range720_1079 = "dashboard-module--range720_1079--136de";
export var reverse = "dashboard-module--reverse--35254";
export var sectionTitle = "dashboard-module--section-title--0d41e";
export var seeMore = "dashboard-module--seeMore--08c40";
export var showCase = "dashboard-module--showCase--80805";
export var text = "dashboard-module--text--3aaaa";
export var textOutline = "dashboard-module--textOutline--c3aa6";
export var transition = "dashboard-module--transition--586ee";
export var transitionBackground = "dashboard-module--transition-background--dedd5";
export var transitionReverse = "dashboard-module--transition-reverse--aa112";
export var upTo2cols = "dashboard-module--upTo2cols--637c5";
export var upTo2colsImgTxt = "dashboard-module--upTo2colsImgTxt--85bad";
export var uppercase = "dashboard-module--uppercase--97573";
export var v2022 = "dashboard-module--v2022--4df5c";
export var videoSection = "dashboard-module--videoSection--d2018";
export var whiteButton = "dashboard-module--whiteButton--4b39c";
export var workProcess = "dashboard-module--workProcess--93896";