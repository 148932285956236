import React, { Fragment } from "react"

export const PROJECTS = [
  {
    id: 1,
    title: "Call Review",
    text: (
      <Fragment>
        <p itemProp="description">
          CallReview is a dashboard solution for call quality assurance
          agencies. Thanks to this dashboard system, call centers will be
          provided with actionable data and results obtained by monitoring every
          customer interaction.
        </p>
        <p itemProp="description">
          The dashboard allows centers to compare their results over a selected
          date range to track an agent’s progress and analyze the agent’s daily
          performance compared to the whole team. A fully integrated
          notification system enables alerts in real-time when certain critical
          actions occur.
        </p>
      </Fragment>
    ),
    imageUrl: "/call_listening/",
    externalLink: "",
    internalLink: "/callreview/",
    linkText: "",
  },
  {
    id: 2,
    title: "Dope360",
    text: (
      <p itemProp="description">
        Service that covers a lot of the marketing needs of small and medium
        businesses in data. Target audience of Dope360 is small and medium
        businesses that mostly have phone call leads. So Dope360 will provide
        call tracking service from the third-party partner, additional data
        about call leads and functionality to create, customize and send
        postcards to any person in the USA, and build your own yard signs, place
        it on the map. The system is integrated with a number of external
        services.
      </p>
    ),
    imageUrl: "/dope360/",
    externalLink: "",
    internalLink: "/dope360/",
    linkText: "dope360.com",
  },
  {
    id: 3,
    title: "Tag Warriors",
    text: (
      <Fragment>
        <p itemProp="description">
          Tag Warriors is a dashboard system integrated with Twitter that helps
          to get data about certain hashtags. Users are enabled to track a
          competition between hashtags and see the counter update in real time.
        </p>
        <p itemProp="description">
          By defining the tweets location, we will show which countries are
          mentioning hashtags the most. The winner is the hashtag that has the
          most mentions.
        </p>
      </Fragment>
    ),
    imageUrl: "/tag_warriors/",
    externalLink: "https://tagwarriors.lasoft.org/",
    internalLink: "",
    linkText: "tagwarriors.lasoft.org",
  },
  {
    id: 4,
    title: "Compuzz",
    text: (
      <Fragment>
        <p itemProp="description">
          Compuzz dashboard is a server side application for brand product
          manufacture factories. The application allows collaborating with a
          final customer, resellers and administration of a printing company.{" "}
        </p>
        <p itemProp="description">
          Compuzz dashboard gives an ability to involve customers into product
          setup and personalization process. Every separate account can create
          its collection of products and settings. The dashboard shows all kinds
          of statistics. User Interface was implemented with Material design
          concept from Google.
        </p>
      </Fragment>
    ),
    imageUrl: "/compuzz/",
    externalLink: "https://en.compuzz.com/",
    internalLink: "",
    linkText: "compuzz.com",
  },
  {
    id: 5,
    title: "Euronavigator",
    text: (
      <Fragment>
        <p itemProp="description">
          Euronavigator is a dashboard system that tracks the process of
          implementation of the EU-Ukraine Association Agreement. The project is
          composed of two applications: The Administrative Panel and Interactive
          infographics.
        </p>
        <p itemProp="description">
          The Administrative Panel is intended to fill and edit data about
          European acts, tasks which are accepted and accomplished by Ukraine to
          fulfill the requirements of the EU-Ukraine Association Agreement. A
          very important function of the Administrative panel is processing data
          that is gathered by Scrapper bot.
        </p>
      </Fragment>
    ),
    imageUrl: "/euronavigator/",
    externalLink: "",
    internalLink: "/euronavigator/",
    linkText: "navigator.eurointegration.com.ua",
  },
]

export const OPTIONS = [
  {
    id: 1,
    title: "Learn",
    text:
      "We carefully learn your need and data in order to understand your problem.",
    image: "/graduate-cap.svg",
  },
  {
    id: 2,
    title: "Draw",
    text:
      "Data visualization expert offers you several designs on how your data may look like on the dashboard",
    image: "/paintbrush.svg",
  },
  {
    id: 3,
    title: "Implement",
    text:
      "Our development team creates the dashboard and integrates it with your system.",
    image: "/code-board.svg",
  },
  {
    id: 4,
    title: "Test",
    text:
      "Using dashboard with your team you make sure the data make sense and is enough for effective decision making.",
    image: "/test-tube.svg",
  },
]

export const DASHBOARD_PROPERTIES = [
  {
    id: 1,
    title: "Innovative",
    text:
      "Our team is aware of the latest trends in dashboards design and user experience",
    image: "/innovative.svg",
  },
  {
    id: 2,
    title: "Flexible",
    text:
      "Visualize your data and place your charts and widgets where you want",
    image: "/flexible.svg",
  },
  {
    id: 3,
    title: "Interactive",
    text:
      "Make ongoing decisions easy and fast thanks to notifications about important updates",
    image: "/interactive.svg",
  },
  {
    id: 4,
    title: "Custom",
    text:
      "Dashboard can be used by many roles within a business and should be customized for each of them",
    image: "/custom.svg",
  },
  {
    id: 5,
    title: "Easy to integrate",
    text:
      "By using the technology you choose, we easily integrate with your current system",
    image: "/puzzle-black.svg",
  },
  {
    id: 6,
    title: "Secure",
    text:
      "Secure transfer of your essential metrics prevents it from falling into third parties",
    image: "/secure.svg",
  },
]

export const ANIMATED_COUNTERS = [
  {
    id: 1,
    title: "Product cases",
    text: (
      <>
        Product cases in Marketing Tech,
        <br /> Data management and others
      </>
    ),
    image: "/product-cases.svg",
    start: 0,
    end: 300,
    delay: 0,
    suffix: "+",
  },
  {
    id: 2,
    title: "Projects",
    text: (
      <>
        Complex SaaS
        <br /> projects delivered
      </>
    ),
    image: "/project.svg",
    start: 0,
    end: 85,
    delay: 0,
    suffix: "+",
  },
  {
    id: 3,
    title: "Team",
    text: (
      <>
        Highly productive
        <br /> co-located teams
      </>
    ),
    image: "/team.svg",
    start: 0,
    end: 20,
    delay: 0,
  },
  {
    id: 4,
    title: "Professionals",
    text: (
      <>
        Multi-talented
        <br /> and experienced professionals
      </>
    ),
    image: "/professional.svg",
    start: 0,
    end: 120,
    delay: 0,
    suffix: "+",
  },
]
